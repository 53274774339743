import { FunctionComponent, useCallback } from "react";

//Assets
import SpringCTLogo from "../../../Resources/Images/sales-images/ic-logo-w-1@2x.png";

//Styles
import "./Footer.scss";

const Footer: FunctionComponent = ({

}) => {

    return (
        <div className="footer3">
            <div className="content9">
                <div className="text-logo3">
                    <div className="an-offering-from5">
                        <div className="an-offering-from6">
                            An offering from
                        </div>
                    </div>
                    <a
                        className="springctcontact5"
                        href="https://springct.com/"
                    >
                        <div className="logo6">
                            <img
                                className="ic-logo-w-1-icon5"
                                alt=""
                                src={SpringCTLogo}
                            />
                        </div>
                    </a>
                </div>
              
                <div className="text7">
                    <div className="spring-computing-technologies2">
                        © 2024 Spring Computing Technologies, All Rights
                        Reserved.
                    </div>
                </div>
                
            </div>
        </div>
    )
}
export default Footer;