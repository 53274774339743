import { FunctionComponent, memo } from "react";

//Styles
import "./HeaderNavigation.scss";

export type HeaderNavigationType = {
  className?: string;
};

const HeaderNavigation: FunctionComponent<HeaderNavigationType> = memo(
  ({ className = "" }) => {
    return (
      <header className={`header-navigation5 ${className}`}>
        <div className="nav-items5">
          <a className="logo19" href="https://proconf.info/" />
        </div>
      </header>
    );
  }
);

export default HeaderNavigation;
