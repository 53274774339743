import {
  FunctionComponent,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import "./ActionToolBar.scss";
import ToolBarButton from "../ToolBarButton/ToolBarButton";
import PortalPopup from "../PortalPopup/PortalPopup";
import PortalDrawer from "../PortalDrawer/PortalDrawer";
import ButtonLeave from "../ButtonLeave/ButtonLeave";
import CallDeviceSelection from "../CallDeviceSelection/CallDeviceSelection";
import VideoDeviceSelection from "../VideoDeviceSelection/VideoDeviceSelection";
import MoreActionsWeb from "../MoreActionsWeb/MoreActionsWeb";
import MobileActionBar from "../MobileActionBar/MobileActionBar";
import {
  onleaveCall,
  onMicMuteUnmute,
  onNoiseCancellationToggle,
  onScreenSharing,
  onVideoMuteUnmute,
} from "../../Redux/Actions/ToolbarActions";
import { useSelector } from "react-redux";
// import arrowIcon from "../../Resources/InCallImages/arrowicon.svg";
import MicOnMediaControlIcon from "../../Resources/InCallImages/MicOnMediaControlIcon.svg";
import MicOffMediaControlIcon from "../../Resources/InCallImages/MicOffMediaControlIcon.svg";
import VideoOffMediaControlIcon1 from "../../Resources/InCallImages/VideoOffMediaControlIcon1.svg";
import VideoOffMediaControlIcon5 from "../../Resources/InCallImages/VideoOffMediaControlIcon5.svg";
// import ChatOffMediaControlIcon7 from "../../Resources/InCallImages/ChatOffMediaControlIcon7.svg";
// import ChatOffMediaControlIcon from "../../Resources/InCallImages/ChatOnMediaControlIcon.svg";
import ParticpantsOnMediaControlIcon from "../../Resources/InCallImages/ParticpantsOnMediaControlIcon.svg";
import ParticpantsOffMediaControlIcon from "../../Resources/InCallImages/ParticpantsOffMediaControlIcon.svg";
import ScreenShareOnMediaControlIcon from "../../Resources/InCallImages/ScreenShareOnMediaControlIcon.svg";
import ChatOffMediaControlIcon2 from "../../Resources/InCallImages/ChatOffMediaControlIcon2.svg";
import MoreOnMediaControlIcon from "../../Resources/InCallImages/MoreOnMediaControlIcon.svg";
import MoreOffMediaControlIcon from "../../Resources/InCallImages/MoreOffMediaControlIcon.svg";
import EndCallMediaControlIcon from "../../Resources/InCallImages/EndCallMediaControlIcon.svg";
import { getTranslation } from "../../Resources/Localization/i18n";
import { CONFIGURATIONS } from "../../Constants/AppConstants";
import { store } from "../../Redux/store";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routes/RouteConstants";
import { isDesktopDevice } from "../../Utility/Utils";
import NoiseCancellationOffIcon from "../../Resources/Images/ic_noise_cancellation_off.svg";
import NoiseCancellationOnIcon from "../../Resources/Images/ic_noise_cancellation_on.svg";

export type ActionToolBarType = {
  className?: string;
  activeRecording?: boolean;
  showChatPanel?: boolean;
  showParticipantsPanel?: boolean;
  showTranscriptPanel?: boolean;
  isScreenShared?: boolean;
  audioMute?: boolean;
  videoMute?: boolean;
  screenShareStarted?: boolean;
  participants?: any;
  isNoiseCancellationEnabled?: boolean;
  toggleChatPanel?: () => void;
  toggleTranscriptPanel?: () => void;
  toggleParticipantsPanel?: () => void;
  toggleRecording?: () => void;
};

const ActionToolBar: FunctionComponent<ActionToolBarType> = ({
  className = "",
  activeRecording,
  showChatPanel,
  showParticipantsPanel,
  showTranscriptPanel,
  isScreenShared,
  audioMute = false,
  videoMute = false,
  screenShareStarted = false,
  participants,
  isNoiseCancellationEnabled = false,
  toggleChatPanel,
  toggleTranscriptPanel,
  toggleParticipantsPanel,
  toggleRecording,
}) => {
  const selectDeviceIconRef = useRef<HTMLButtonElement>(null);
  const [isCallDeviceSelectionPopupOpen, setCallDeviceSelectionPopupOpen] =
    useState(false);
  const selectDeviceIcon1Ref = useRef<HTMLButtonElement>(null);
  const [isVideoDeviceSelectionPopupOpen, setVideoDeviceSelectionPopupOpen] =
    useState(false);
  const actionWrapper3Ref = useRef<HTMLButtonElement>(null);
  const [isMoreActionsWebPopupOpen, setMoreActionsWebPopupOpen] =
    useState(false);
  const actionWrapper5Ref = useRef<HTMLButtonElement>(null);
  const [isButtonLeavePopupOpen, setButtonLeavePopupOpen] = useState(false);
  const [isMobileActionBarOpen, setMobileActionBarOpen] = useState(false);
  const isModerator = useSelector(
    (state: any) => state.ParticipantReducer?.localParticipant?.isModerator
  );
  const isGuestFlow = store.getState()?.RoomReducer.userName ?? false;
  const navigate = useNavigate();
  const adminConfig = useSelector(
    (state: any) => state.LoginReducer?.configurations
  );

  useEffect(() => {
    window.addEventListener("orientationchange", closeMobileActionBar);
  }, []);

  // const openCallDeviceSelectionPopup = useCallback(() => {
  //   setCallDeviceSelectionPopupOpen(true);
  // }, []);

  const closeCallDeviceSelectionPopup = useCallback(() => {
    setCallDeviceSelectionPopupOpen(false);
  }, []);

  // const openVideoDeviceSelectionPopup = useCallback(() => {
  //   setVideoDeviceSelectionPopupOpen(true);
  // }, []);

  const closeVideoDeviceSelectionPopup = useCallback(() => {
    setVideoDeviceSelectionPopupOpen(false);
  }, []);

  const openMoreActionsWebPopup = useCallback(() => {
    setMoreActionsWebPopupOpen(true);
  }, []);

  const closeMoreActionsWebPopup = useCallback(() => {
    setMoreActionsWebPopupOpen(false);
  }, []);

  const openButtonLeavePopup = useCallback(() => {
    setButtonLeavePopupOpen(true);
  }, []);

  const closeButtonLeavePopup = useCallback(() => {
    setButtonLeavePopupOpen(false);
  }, []);

  const openMobileActionBar = useCallback(() => {
    setMobileActionBarOpen(true);
  }, []);

  const closeMobileActionBar = useCallback(() => {
    setMobileActionBarOpen(false);
  }, []);

  // Leave on end call button click for guest user or non-moderator
  const onEndToolIconClick = () => {
    if (isGuestFlow) {
      onleaveCall();
      navigate(ROUTES.FEEDBACK);
    } else if (isModerator) {
      onleaveCall();
      navigate(ROUTES.FEEDBACK);
    }
    // else {
    //     onEndCallButtonClick()
    // }
  };

  return (
    <>
      <div className={`actiontoolbar-main ${className}`}>
        <div className="actionsframe">
          <div className="mediaactionwrapper">
            <ToolBarButton
              propBorder={!audioMute ? "1px solid #2a8067" : "none"}
              propBackgroundColor={!audioMute ? "#c6ead9" : "transparent"}
              toolbarButtonOffIcon={MicOffMediaControlIcon}
              toolbarButtonLabel={getTranslation("mic")}
              propColor={!audioMute ? "#121313" : "none"}
              toolbarButtonOnIcon={MicOnMediaControlIcon}
              isOn={!audioMute}
              toolBarButtonClick={() => onMicMuteUnmute(audioMute)}
            />

            {/* <button
              className="selectdeviceicon"
              ref={selectDeviceIconRef}
              onClick={openCallDeviceSelectionPopup}
            >
              <img className="arrowicon" alt="" src={arrowIcon} />
            </button> */}
          </div>
          {CONFIGURATIONS.VIDEO_CALL in adminConfig && (
            <div className="mediaactionwrapper">
              <ToolBarButton
                propBorder={!videoMute ? "1px solid #2a8067" : "none"}
                propBackgroundColor={!videoMute ? "#c6ead9" : "transparent"}
                toolbarButtonOffIcon={VideoOffMediaControlIcon5}
                toolbarButtonLabel={getTranslation("video")}
                propColor={!videoMute ? "#121313" : "none"}
                toolbarButtonOnIcon={VideoOffMediaControlIcon1}
                isOn={!videoMute}
                toolBarButtonClick={() => onVideoMuteUnmute(videoMute)}
              />
              {/* <button
                className="selectdeviceicon"
                ref={selectDeviceIcon1Ref}
                onClick={openVideoDeviceSelectionPopup}
              >
                <img className="arrowicon" alt="" src={arrowIcon} />
              </button> */}
            </div>
          )}
          {/* {CONFIGURATIONS.LIVE_CHAT in adminConfig && (
            <button className="actionwrapper">
              <ToolBarButton
                propBorder={showChatPanel ? "1px solid #2a8067" : "none"}
                propBackgroundColor={showChatPanel ? "#c6ead9" : "transparent"}
                toolbarButtonOffIcon={ChatOffMediaControlIcon7}
                toolbarButtonLabel={getTranslation("chat")}
                toolBarButtonClick={toggleChatPanel}
                propColor={showChatPanel ? "#121313" : "none"}
                toolbarButtonOnIcon={ChatOffMediaControlIcon}
                isOn={showChatPanel}
              />
            </button>
          )} */}
          <button className="actionwrapper">
            <ToolBarButton
              propBorder={showParticipantsPanel ? "1px solid #2a8067" : "none"}
              propBackgroundColor={
                showParticipantsPanel ? "#c6ead9" : "transparent"
              }
              toolbarButtonOffIcon={ParticpantsOffMediaControlIcon}
              toolbarButtonLabel={getTranslation("participants")}
              propColor={showParticipantsPanel ? "#121313" : "none"}
              toolbarButtonOnIcon={ParticpantsOnMediaControlIcon}
              toolBarButtonClick={toggleParticipantsPanel}
              isOn={showParticipantsPanel}
            />
          </button>
          {CONFIGURATIONS.SCREEN_SHARING in adminConfig && isDesktopDevice && (
            <button
              className="actionwrapper"
              disabled={isScreenShared || participants.length < 2}
            >
              <ToolBarButton
                className={
                  isScreenShared || participants.length < 2 ? "disabled" : ""
                }
                propBorder={screenShareStarted ? "1px solid #2a8067" : "none"}
                propBackgroundColor={
                  screenShareStarted ? "#c6ead9" : "transparent"
                }
                toolbarButtonOffIcon={ChatOffMediaControlIcon2}
                toolbarButtonLabel={
                  screenShareStarted
                    ? getTranslation("stopSharing")
                    : getTranslation("shareScreen")
                }
                propColor="#121313"
                toolbarButtonOnIcon={ScreenShareOnMediaControlIcon}
                isOn={screenShareStarted}
                toolBarButtonClick={() => onScreenSharing(screenShareStarted)}
              />
            </button>
          )}
          <div className="mediaactionwrapper">
            <ToolBarButton
              propBorder={
                isNoiseCancellationEnabled ? "1px solid #2a8067" : "none"
              }
              propBackgroundColor={
                isNoiseCancellationEnabled ? "#c6ead9" : "transparent"
              }
              toolbarButtonOffIcon={NoiseCancellationOffIcon}
              toolbarButtonLabel={getTranslation("BNC")}
              propColor={isNoiseCancellationEnabled ? "#121313" : "none"}
              toolbarButtonOnIcon={NoiseCancellationOnIcon}
              isOn={isNoiseCancellationEnabled}
              toolBarButtonClick={() =>
                onNoiseCancellationToggle(!isNoiseCancellationEnabled)
              }
            />
          </div>
          <button
            className="actionwrapper"
            ref={actionWrapper3Ref}
            onClick={openMoreActionsWebPopup}
          >
            <ToolBarButton
              propBorder={
                isMoreActionsWebPopupOpen ? "1px solid #2a8067" : "none"
              }
              propBackgroundColor={
                isMoreActionsWebPopupOpen ? "#c6ead9" : "transparent"
              }
              toolbarButtonOffIcon={MoreOffMediaControlIcon}
              toolbarButtonLabel={getTranslation("more")}
              propColor={isMoreActionsWebPopupOpen ? "#121313" : "none"}
              toolbarButtonOnIcon={MoreOnMediaControlIcon}
              isOn={isMoreActionsWebPopupOpen}
            />
          </button>
          <button className="actionwrapper4" onClick={openMobileActionBar}>
            <ToolBarButton
              propBorder={isMobileActionBarOpen ? "1px solid #2a8067" : "none"}
              propBackgroundColor={
                isMobileActionBarOpen ? "#c6ead9" : "transparent"
              }
              toolbarButtonOffIcon={MoreOffMediaControlIcon}
              toolbarButtonLabel={getTranslation("more")}
              propColor={isMobileActionBarOpen ? "#121313" : "none"}
              toolbarButtonOnIcon={MoreOnMediaControlIcon}
              isOn={isMobileActionBarOpen}
            />
          </button>
          <button
            className="actionwrapper5"
            ref={actionWrapper5Ref}
            onClick={isModerator ? openButtonLeavePopup : onEndToolIconClick}
          >
            <ToolBarButton
              propBorder="none"
              propBackgroundColor="#e25c41"
              toolbarButtonOffIcon={EndCallMediaControlIcon}
              toolbarButtonLabel={getTranslation("endCall")}
              propColor="#fff"
              toolbarButtonOnIcon={EndCallMediaControlIcon}
              isOn={false}
            />
          </button>
        </div>
      </div>
      {isCallDeviceSelectionPopupOpen && (
        <PortalPopup
          placement="Top right"
          relativeLayerRef={selectDeviceIconRef}
          onOutsideClick={closeCallDeviceSelectionPopup}
        >
          <CallDeviceSelection onClose={closeCallDeviceSelectionPopup} />
        </PortalPopup>
      )}
      {isVideoDeviceSelectionPopupOpen && (
        <PortalPopup
          placement="Top right"
          relativeLayerRef={selectDeviceIcon1Ref}
          onOutsideClick={closeVideoDeviceSelectionPopup}
        >
          <VideoDeviceSelection onClose={closeVideoDeviceSelectionPopup} />
        </PortalPopup>
      )}
      {isMoreActionsWebPopupOpen && (
        <PortalPopup
          placement="Top right"
          relativeLayerRef={actionWrapper3Ref}
          onOutsideClick={closeMoreActionsWebPopup}
        >
          <MoreActionsWeb
            onClose={closeMoreActionsWebPopup}
            toggleTranscriptPanel={toggleTranscriptPanel}
            toggleRecording={toggleRecording}
            activeRecording={activeRecording}
          />
        </PortalPopup>
      )}
      {isButtonLeavePopupOpen && (
        <PortalPopup
          placement="Top right"
          relativeLayerRef={actionWrapper5Ref}
          onOutsideClick={closeButtonLeavePopup}
        >
          <ButtonLeave onClose={closeButtonLeavePopup} />
        </PortalPopup>
      )}
      {isMobileActionBarOpen && (
        <PortalDrawer
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Bottom"
          onOutsideClick={closeMobileActionBar}
        >
          <MobileActionBar
            activeRecording={activeRecording}
            showParticipantsPanel={showParticipantsPanel}
            showChatPanel={showChatPanel}
            showTranscriptPanel={showTranscriptPanel}
            onClose={closeMobileActionBar}
            toggleRecording={toggleRecording}
            toggleTranscriptPanel={toggleTranscriptPanel}
            toggleParticipantsPanel={toggleParticipantsPanel}
            toggleChatPanel={toggleChatPanel}
          />
        </PortalDrawer>
      )}
    </>
  );
};

export default ActionToolBar;
