import { FunctionComponent, useMemo, type CSSProperties } from "react";
import "./FeatureCard.css";

export type FeatureCardType = {
  className?: string;
  featureImage?: string;
  featureTitle?: string;
  featureDescription?: string;

  /** Style props */
  featureDescriptionFlexDirection?: CSSProperties["flexDirection"];
};

const FeatureCard: FunctionComponent<FeatureCardType> = ({
  className = "",
  featureDescriptionFlexDirection,
  featureImage,
  featureTitle,
  featureDescription,
}) => {
  const feature1Style: CSSProperties = useMemo(() => {
    return {
      flexDirection: featureDescriptionFlexDirection,
    };
  }, [featureDescriptionFlexDirection]);

  return (
    <div className={`feature-1 ${className}`} style={feature1Style}>
      <img className="feature-image-icon" alt="" src={featureImage} />
      <div className="feature-description">
        <div className="feature-title">{featureTitle}</div>
        <div className="feature-description1">{featureDescription}</div>
      </div>
    </div>
  );
};

export default FeatureCard;
