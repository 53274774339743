import SalesAxiosHelper from "./SalesAxiosHelper";

export function callSalesAPI(requestObject: object) {
    return SalesAxiosHelper(requestObject)
        .then((response) => {
            return {
                status: 200,
                response: response.data,
                responseHeaders: response.headers,
            };
        })
        .catch((error) => {
            return error;
        });
}