export const ROUTES = {
    ROOT_PATH: "/",
    LOGIN: "/login",
    DASHBOARD: "/dashboard",
    LANDING_PAGE: "/meetingList",
    CREATE_MEETING: "/meetingPreview",
    IN_CALL: "/inCall", 
    ABOUT: "/about",

    //sales-demo-routes
    FEEDBACK:"/feedback",
    
}