
import { useNavigate } from "react-router-dom";

//Components
import FeedbackPage from "./FeedbackPage";

//Constants
import { ROUTES } from "../../Routes/RouteConstants";
import { useState } from "react";
import LoadingSpinner from "../../Common Components/LoadingSpinner/LoadingSpinner";
import { getParticipantDetails, submitUserFeedback } from "../Redux/Actions/SalesAction";
import { store } from "../../Redux/store";
import { useSelector } from "react-redux";
import { API_STATUS } from "../../Constants/AppConstants";

export default function FeedbackPageContainer() {

    const meetingId = useSelector(
        (state: any) => state.SalesReducer?.meetingId
    );

    console.log('meetingId: ', store.getState().SalesReducer, meetingId);

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const onSkipClick = () => {
        navigate(ROUTES.ROOT_PATH)
    }

    const onFeedbackSubmit = async (name: string, organization: string, email: string, comments: string) => {

        setIsLoading(true)

        let response = await submitUserFeedback(name, organization, email, comments)

        setIsLoading(false)

        if (response?.status === API_STATUS.SUCCESS) {

            navigate(ROUTES.ROOT_PATH)
        }

    }

    const getInviteeParticipantDetails = () => {
        let resp = getParticipantDetails()
        return resp;
    }

    return (
        <>
            {isLoading && <LoadingSpinner />}

            <FeedbackPage
                onSkipClick={onSkipClick}
                onFeedBackSubmit={(
                    name: string,
                    organization: string,
                    email: string,
                    comments: string
                ) =>
                    onFeedbackSubmit(
                        name,
                        organization,
                        email,
                        comments
                    )
                }
                getInviteeParticipantDetails={getInviteeParticipantDetails}
            />
        </>
    )
}