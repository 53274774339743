import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

//Routes
import routes from "./Routes/Routes";

//Components
import PrivateRoutes from "./Routes/PrivateRoutes";
import LandingPage from "./Common Components/LandingPage/LandingPage";
import ErrorPage from "./Components/404Page/404Page";
import NotificationComponent from "./Common Components/Notification/Notification";
import About from "./Components/About/About";
import StartMeetingContainer from "./Sales-Flow/StartMeeting/StartMeetingContainer";

//Style
import "./App.scss";

//service
import proConfService from "./Services/ProConfService";

function App() {
	const notificationRef = useRef<any>(null);
	const errorMessage = useSelector(
		(state: any) => state.NotificationReducer?.errorMessage
	);
	const successMessage = useSelector(
		(state: any) => state.NotificationReducer?.successMessage
	);
	const warningMessage = useSelector(
		(state: any) => state.NotificationReducer?.warningMessage
	);

  // Code to execute after the page has been reloaded
  useEffect(() => {
    (async () => {
      await proConfService.initProConf();
    })();
  }, []);

	const location = useLocation();
	useEffect(() => {
		if (!location.pathname.includes("about")) {
			window.history.pushState(null, "/", window.location.href);
			window.history.back();
			window.onpopstate = () => window.history.forward();
			window.history.pushState(null, document.title, window.location.href);
		}
	}, [location]);

	useEffect(() => {
		if (notificationRef.current) {
			if (errorMessage) {
				notificationRef.current.openNotification(
					"error",
					"Error",
					errorMessage
				);
			} else if (successMessage) {
				notificationRef.current.openNotification(
					"success",
					"Success",
					successMessage
				);
			} else if (warningMessage) {
				notificationRef.current.openNotification(
					"warning",
					"Warning",
					warningMessage
				);
			}
		}
	}, [errorMessage, successMessage, warningMessage]);

	return (
		<>
			<NotificationComponent ref={notificationRef} />
			<Routes>
				<Route path="/" element={<LandingPage />} />
				{routes.map((route, index) => {
					const { Component, ...rest } = route;
					return (
						<Route element={<PrivateRoutes {...rest} />} key={`route-${index}`}>
							<Route key={index} element={<Component />} {...rest} />
						</Route>
					);
				})}

				<Route path="/:meetingId" element={<StartMeetingContainer />} />


				{/* <Route path="/about" element={<About />} /> */}
				<Route path="*" element={<ErrorPage />}></Route>
			</Routes>
		</>
	);
}
export default App;
