import { createStore, applyMiddleware, AnyAction, Store } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer, Persistor } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import { ThunkDispatch, thunk } from "redux-thunk";

//root
import rootReducer from "./rootReducer";

//state types
import {
  ProConfState,
  LoginState,
  NotificationState,
  ParticipantState,
  RoomState,
  ToolbarState,
  InCallState,
} from "./StateTypes/StateTypes";
import { SalesState } from "../Sales-Flow/Redux/Reducer/SalesReducer";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
//create persisted store for getting data after page refresh or close
const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: [
    "ProConfReducer",
    "LoginReducer",
    "RoomReducer",
    "ParticipantReducer",
    "ToolbarReducer",
    "InCallReducer",
    "NotificationReducer",
    "SalesReducer"
  ], // Only persist this part of the state
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// mount it on the Store
export const store = createStore(
  persistedReducer,
  applyMiddleware(thunk, sagaMiddleware)
);

type AppStore = Store<
  Partial<
    | ProConfState
    | LoginState
    | NotificationState
    | RoomState
    | ParticipantState
    | ToolbarState
    | InCallState
    | SalesState
  >,
  AnyAction
> & {
  dispatch: ThunkDispatch<any, undefined, AnyAction>;
};

const appStore = store as AppStore;

// Create persistor
export const persistor: Persistor = persistStore(appStore);
