import { RefObject, useState } from "react";
import { Input } from "antd";

//Aeets
import crossIcon from "../../Resources/Images/icadd2@2x.png"

//Translation
import { getTranslation } from "../../Resources/Localization/i18n";

//Styles
import "./SuccessMsgPopup.scss";

export type JoinMeetingPopUpType = {
    onCloseClick: any,
};

const SuccessMsgPopup: React.FC<JoinMeetingPopUpType> = ({
    onCloseClick,
}) => {
    return (
        <div className={`success-msg-pop-up suceess-popup`}>
            <div>{getTranslation("requestDemoSuccess")}</div>
            <div>{getTranslation("ourRep")}</div>
            <div className="btn-panel">
                <button className="buttonprimary2" onClick={onCloseClick}>
                    <b className="buttonlabel3">{getTranslation("ok")}</b>
                </button>
            </div>
        </div>
    );
};

export default SuccessMsgPopup;
