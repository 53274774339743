import { FunctionComponent } from "react";

//Components
import FeatureCard from "../CommonComponents/FeatureCard/FeatureCard";

//Assets
import Feature1 from "../../Resources/Images/sales-images/Feature 1 Image.webp";
import Feature2 from "../../Resources/Images/sales-images/Feature 2 Image.webp";
import Feature3 from "../../Resources/Images/sales-images/Feature 3 Image.webp";
import Feature4 from "../../Resources/Images/sales-images/Feature 4 Image.webp";

//Styles
import "./KeyFeatures.css";

export type KeyFeaturesType = {
  className?: string;
};

const KeyFeatures: FunctionComponent<KeyFeaturesType> = ({
  className = "",
}) => {
  return (
    <div className={`key-features2 ${className}`}>
      <div className="content23">
        <FeatureCard
          featureImage={Feature1}
          featureTitle={`Call Quality, Bandwidth Management & Cost Optimization`}
          featureDescription="ProCONF offers various admin controls using which you can control bandwidth, framerate and resolution of stream. These parameters directly affect call quality and stream transmission cost. Fine tune these parameters as suitable for your business with your cloud ProCONF deployment."
        />
        <FeatureCard
          featureDescriptionFlexDirection="row-reverse"
          featureImage={Feature2}
          featureTitle="Call Summary and Transcript"
          featureDescription="You don’t have to spend time manually documenting minutes of the meeting and action decided there on. ProCONF has this unique feature that will automatically create meeting summary based on transcripts."
        />
        <FeatureCard
          featureDescriptionFlexDirection="row"
          featureImage={Feature3}
          featureTitle="White Labelling"
          featureDescription="Code is power. Make branding changes to ProCONF SDKs to match your brand, logos and color scheme. The communication platform can seamlessly blend in your application to bring video calling capabilities."
        />
        <FeatureCard
          featureDescriptionFlexDirection="row-reverse"
          featureImage={Feature4}
          featureTitle="Security"
          featureDescription="No more worry about the security of your video conferencing data. With ProCONF platform deployment in your cloud you will have absolute control on video data routing, recording and retention of data. Security if must for all conferencing application however it is mandatory for HIPPAA applications. "
        />
        <div className="bottomtextframe">
          <div className="bottomtext">{`To know all the benefits of ProCONF, please schedule a call. We will be happy to walk you through various features and how you can customize them in your deployment. `}</div>
        </div>
      </div>
    </div>
  );
};

export default KeyFeatures;
