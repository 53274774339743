// add rest api urls here
export const URLS = {
  LOGIN: "auth/login",
  JOIN_AS_GUEST: "auth/joinmember",
  ADMIN_DETAILS: "version",
  INVITE_PARTICIPANTS: "inviteParticipants",
};

export const SALES_URLS = {
  REQUEST_DEMO: "/request-demo-email",
  UPDATE_VISITED_COUNT: "/meeting-visited?room_id=",
  FEEDBACK: "/feedback",
  TOKENIZE_URL: "/tokenize-url?room_id=",
  PARTICIPANT: "/participant",
  INVITE_PARTICIPANTS: "/invite-sales-participant",
  COPY_INVITE_LINK: "/create-invite-link",
  SUMMARY_FEEDBACK: "/summaryFeedback",
  UPDATE_ROOM_ID: "/update-roomid",
};

// APIs that require authtoken in the header
export const URLS_WITH_TOKEN = [
  URLS.INVITE_PARTICIPANTS,
  SALES_URLS.INVITE_PARTICIPANTS,
];
