import React, { useEffect, useRef, useState } from 'react'
import { Input } from "antd";

//Components
import HeaderNavigation from '../CommonComponents/HeaderNavigation/HeaderNavigation';
import Footer from '../CommonComponents/Footer/Footer';
import FeatureCards from "../KeyFeatures/KeyFeatures";

//Action
import { setErrorNotification } from '../../Redux/Actions/NotificationAction';


//Assets
import HeroImage from "../../Resources/Images/sales-images/imagebannercommon@2x.webp";
import Union from "../../Resources/Images/sales-images/union.svg";

//Styles
import "./StartMeeting.scss"


interface StartMeetingFormProps {
    onStartMeeting: (userName: string, roomName: string) => void;
    onJoinMeeting: (userName: string) => void;
    isParticipant: boolean,
    getUserDetails: () => string;
    resetRoom: boolean;
    setResetRoom: (value: boolean) => void
}

const StartMeetingForm: React.FC<StartMeetingFormProps> = ({ onStartMeeting, onJoinMeeting, isParticipant, getUserDetails, resetRoom, setResetRoom }) => {
    console.log('isParticipant: ', isParticipant);
    const startButtonRef = useRef<HTMLButtonElement>(null);
    const joinButtonRef = useRef<HTMLButtonElement>(null);

    const [userName, setUserName] = useState("");
    const [roomName, setRoomName] = useState("");

    useEffect(() => {
        if (resetRoom)
            setRoomName("");
    }, [resetRoom])

    // Handler function to update state when input value changes
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
        console.log('handleInputChange: user ', handleInputChange);
        switch (type) {
            case "userName":
                setUserName(e.target.value)
                sessionStorage.setItem("participantName", JSON.stringify(e.target.value));
                sessionStorage.setItem("uname", JSON.stringify(e.target.value))

                break
            case "roomName":
                setResetRoom(false);
                setRoomName(e.target.value)
                break
        }
    };

    const onStartMeeitng: React.MouseEventHandler<HTMLButtonElement> = () => {

        if (userName && roomName) {
            onStartMeeting(userName, roomName)
        }
        else {
            setErrorNotification("Please enter User name and Meeting name!")
        }
    }

    const onJoinMeeitng: React.MouseEventHandler<HTMLButtonElement> = () => {
        if ((userName)) {
            onJoinMeeting(userName)
        } else {
            setErrorNotification("Please enter User name!")
        }
    }

    const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        // Check if the pressed key is Enter (key code 13)
        if (event.key === 'Enter') {
            // Programmatically click the button
            if (startButtonRef.current && !startButtonRef.current.disabled) { onStartMeeting(userName, roomName) }
            else if (joinButtonRef.current && !joinButtonRef.current.disabled) {
                onJoinMeeting(userName)
            }
        };
    }

    return (
        <div className="proconflanding-intro">
            <HeaderNavigation />
            <div className={`banner3`}>
                <div className="content18">
                    <img
                        className="imagebannercommon-icon4"
                        alt=""
                        src={HeroImage}
                    />
                    <div className="outercontent1">
                        <div className="text6">
                            <div className="welcome-to-proconf">Welcome to ProCONF Meeting</div>
                            <div className="subtext12">
                                <div className="pointframe2">
                                    <div className="pointframe-inner" />
                                </div>
                                <div className="highly-scalable-audio3">
                                    Highly scalable audio-video conferencing platform
                                </div>
                            </div>
                        </div>

                        <div className="fields2">

                            <div className="username1">
                                <Input
                                    className="business-email-id5"
                                    size="large"
                                    placeholder="User Name"
                                    bordered={true}
                                    value={userName}
                                    onChange={(e) => handleInputChange(e, "userName")}
                                    maxLength={100}
                                    onKeyPress={handleEnterKeyPress}
                                />
                            </div>

                            {(!isParticipant) && <div className="username1">
                                <Input
                                    className="business-email-id5"
                                    size="large"
                                    placeholder="Meeting Name"
                                    bordered={true}
                                    value={roomName}
                                    onChange={(e) => handleInputChange(e, "roomName")}
                                    maxLength={100}
                                    onKeyPress={handleEnterKeyPress}
                                />
                            </div>}

                        </div>
                        {!isParticipant ? <button className="btn-banner2 " onClick={onStartMeeitng}
                        >
                            <div className="innerframebtn1">

                                <div className="meetingicon3">
                                    <img className="union-icon6" alt="" src={Union} />
                                </div>
                                <b className="banner3request2">Start Meeting</b>
                            </div>
                        </button>
                            : <button className="btn-banner2 " onClick={onJoinMeeitng}
                            >
                                <div className="innerframebtn1">

                                    <div className="meetingicon3">
                                        <img className="union-icon6" alt="" src={Union} />
                                    </div>
                                    <b className="banner3request2">Join Meeting</b>
                                </div>
                            </button>}
                    </div >
                </div>
            </div>
            <FeatureCards />

            <Footer />

        </div>
    );
};

export default StartMeetingForm;
