
export interface SalesState {
    meetingId: string;
    participantId: string;
    participantName: string
}

// Define the initial state for the ProConf reducer
const initialState: SalesState = {
    meetingId: '',
    participantId: "",
    participantName: ''
};

// Reducer function to handle state changes based on actions
const SalesReducer = (
    state = initialState,
    action: any
): SalesState => {

    switch (action.type) {
        case "SET_MEETING_ID":
            return { ...state, meetingId: action.payload };

        case "SET_PARTICIPANT_ID":
            return { ...state, participantId: action.payload };

        case "SET_PARTICIPANT_NAME":
            return { ...state, participantName: action.payload };

        default:
            return state;
    }

};

export default SalesReducer;
